import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles';
import FiCard from "../../components/ficard"
import NewPosts from "../../components/newPosts"
import FWCard from "../../components/fullWidthCard"
import Counter from "../../components/counter";
import FWBanner from "../../components/fullWidthBanner";




const counterItems = [{
  label: 'Countries',
  number: 16
},
{
  label: 'Cats',
  number: 350000
},
{
  label: 'Dogs',
  number: 175000
},
{
  label: 'Happy Pet Parents',
  number: 480000
},
]

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: theme.spacing(5),

  },
  promoSection: {
    marginTop: theme.spacing(1),
  },
  sectionHeader: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  philosophySection: {
    marginTop: theme.spacing(10),
    backgroundColor: '#EEEEEE',
    padding: '16px'
  },
  differencesSection: {
    backgroundColor: '#EEEEEE',
    marginTop: theme.spacing(10),

  },
  aboutUsSection: {
    display: 'flex',
    marginTop: theme.spacing(1),
    backgroundColor: '#F1F1F1;',
    flexWrap: 'no-wrap'
  },
  sectionWrapper: {
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  marginTop: {
    marginTop: theme.spacing(5)
  },
  cardGridWrapper: {
    flex: '1 0 100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    justifyContent: 'center',
    gap: '2%',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
    }
  }






}))

const IndexPage = ({ location }) => {

  const classes = useStyles()
  const locale = 'en-EU'
  return (
    <Layout nodeLocale={locale} location={location}>
      <SEO
        title="Homepage"
        lang={locale}
        location={location}
      />
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12}>
          <FWBanner imgName='main-banner-desktop.jpg' />
        </Grid>

        <Grid item xs={12} className={classes.marginTop}>
          <Grid container className={classes.cardGridWrapper}>
            {counterItems.map((item, index) =>
              <Counter key={index} number={item.number} label={item.label} />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='Our Story' subtitle="Three friends came together with the idea to prepare food for their beloved pets. Their aim was to make sure their pets did not eat anything they themselves wouldn’t! So with the help of ..." actions={[{ to: '/en/about-us/who-we-are/', label: 'Read More' }]} image='home-about-us-mobile+desktop.jpg' position='right' />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FiCard img='home-explore-cat-products-desktop.jpg' title='Feel Like A Gourmet' subtitle='“Say goodbye to cheap fast food style foods. Budget friendly, extra delicious Prochoice cat foods are prepared with fresh ingredients. Explore our delicious recipes.' controls={[{ link: '/en/products/cat/dry-food', label: 'Dry Food' }, { link: '/en/products/cat/wet-food/', label: 'Wet Food' }]} left dark />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='Our Ingredients' subtitle="Our team of veterinarians and animal nutrition experts makes sure to pick the highest quality ingredients with care for every recipe we make. Our ingredients have a lot of benefits for our furry friends!" actions={[{ to: '/en/about-us/ingredients/', label: 'Read More' }]} image='home-fresh-ingredients.jpg' position='left' />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='Happy Pets in 16 Countries' subtitle="From production to shelves, we deliver our foods as quickly as possible with perfect logistics planning and process management. This way, our products are always available fresh,  no matter where they are in the world." image='world-map.jpg' position='right' />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FiCard img='home-explore-dog-products-desktop.jpg' title="Man's Best Friend Deserves The Best" subtitle="Prepared with fresh ingredients and topped with superfoods, Prochoice is exceptionally appetizing and beneficial! Do a favor for your best friend and feed with Prochoice." controls={[{ link: '/en/products/dog/dry-food/', label: 'Dry Food' }, { link: '/en/products/dog/wet-food/', label: 'Wet Food' }]} dark />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <NewPosts locale={locale} />
          </Grid>

        </Grid>

        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FiCard img='main-page-contact-us-desktop.jpg' title='Become Our Exclusive Distributor For Your Country' subtitle="Don't hesitate. Contact us anytime, we are here to help." controls={[{ link: '/en/exports/', label: 'Learn More' }]} left dark />
          </Grid>
        </Grid>




      </Grid>


    </Layout>

  )
}


export default IndexPage
